import { Action, Order, OrderFailureType, Restaurant } from '@wix/restaurants-client-logic';
import { Dispatch } from 'redux';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';
import { setErrorType, submitOrderSuccess } from './checkout.actions';
import { clearSessionStorage, navigate, openModal } from '../session/session.actions';
import { Modals, RouteUrls } from '../../core/constants';

export function handleOrderSuccess({
  orderResponse,
  dispatch,
  flowAPI,
  requestId = '',
}: {
  orderResponse: Order;
  dispatch: Dispatch<Action<any>>;
  flowAPI: ControllerFlowAPI;
  requestId?: string;
}) {
  dispatch(submitOrderSuccess({ orderResponse }));
  dispatch(navigate({ routeUrl: RouteUrls.THANK_YOU }));
  dispatch(clearSessionStorage());
  if (flowAPI.biLogger) {
    flowAPI.biLogger.submitOrderSuccess({
      orderId: orderResponse.id,
      oloRequestId: requestId,
    });
  }
}

export function handleOrderFailure({
  orderResponseType = '',
  orderResponseDetail = '',
  orderResponseCode,
  orderResponseChargeId,
  dispatch,
  flowAPI,
  requestId = '',
}: {
  orderResponseType?: string;
  orderResponseDetail?: string;
  orderResponseCode?: number;
  orderResponseChargeId?: string;
  dispatch: Dispatch<Action<any>>;
  flowAPI: ControllerFlowAPI;
  requestId?: string;
}) {
  let errorType = '';
  if (orderResponseType) {
    errorType = orderResponseType.substring(orderResponseType.lastIndexOf('/') + 1);
  }
  dispatch(
    setErrorType({
      errorType: errorType as OrderFailureType,
      errorCode: orderResponseCode,
      errorChargeId: orderResponseChargeId,
    }),
  );

  dispatch(openModal({ modal: Modals.ORDER_FAILURE_MODAL }));
  if (flowAPI.biLogger) {
    flowAPI.biLogger.submitOrderFailure({
      rejectReason: orderResponseType,
      oloRequestId: requestId,
    });
    if (orderResponseDetail && orderResponseDetail.includes('earliest allowed delivery.time')) {
      flowAPI.biLogger.futureOrdersError({ details: orderResponseDetail.split('order')[0] });
    }
  }
}

export function reportSubmitOrderBiEvent(
  flowAPI: ControllerFlowAPI,
  restaurant: Restaurant,
  requestId: string,
  total: number,
  totalItemsCount: number,
  isContactless: boolean,
) {
  if (flowAPI.biLogger) {
    flowAPI.biLogger.submitOrder({
      currency: restaurant.currency,
      oloRequestId: requestId,
      total,
      totalItemsCount,
      isContactless,
    });
  }
}
